<template>
  <v-content>
    <v-container fluid fill-height>
      <v-layout align-center justify-center column>
        <FileImage
          :file="getLogo()"
          class="logo-campaign"
          :useBg="true"
          v-if="getLogo() != null"
        />
        <div class="logo-login" v-else></div>
        <Container maxWidth="570">
          <v-layout row wrap px-4>
            <v-flex v-if="userEmail" form-box pa-5>
              <div class="head-login mb-4">
                <h2>Verifique seu email</h2>
              </div>
              <p>
                Enviamos para o email
                <a class="text-primary decor-none font-weight-bold">{{
                  userEmail
                }}</a>
                um link para a troca de senha.
              </p>
              <p>Caso não encontre o email verifique sua caixa de spam.</p>
              <div class="text-xs-center">
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn-primary px-5 mt-4"
                  large
                  @click="$router.go(-1)"
                  >Voltar</v-btn
                >
              </div>
            </v-flex>
            <v-flex v-else form-box pa-5>
              <div class="head-login mb-4">
                <h2>Esqueceu a senha?</h2>
                <p>
                  Informe abaixo seu e-mail ou CPF para redefinir sua senha.
                </p>
              </div>
              <div class="box-xs-form mt-3">
                <InputText
                  type="text"
                  v-model="username"
                  textLabel="E-mail ou CPF"
                  place-holder="Informe seu e-mail ou CPF cadastrado"
                />
              </div>
              <Recaptcha
                ref="recaptcha2"
                :key="3"
                v-if="landingPageConfig.campaign.withRecaptcha"
                @recaptchaSuccess="recaptchaSuccess"
              />
              <div class="text-xs-center">
                <v-btn
                  flat
                  round
                  color="white"
                  class="btn-primary px-5 mt-4"
                  large
                  @click="sendRecoverPasswordEmail"
                  >Redefinir senha</v-btn
                >
              </div>
              <div class="mt-5 password-redefinition-footer">
                <p>
                  Você receberá em seu e-mail um link para que você possa
                  redefinir sua senha.
                </p>
                <p>
                  Caso não o receba, verifique sua caixa de spam ou o lixo
                  eletrônico.
                </p>
                <p>
                  Se necessário entre em contato conosco através do email
                  <a class="text-primary decor-none font-weight-bold"
                    >contato@abraceumacausa.com.br.</a
                  >
                  seu e-mail será respondido em até 48 horas úteis
                </p>
                <p>
                  Para receber nossos e-mails no futuro lembre-se de coloca-los
                  como confiável em seu e-mail.
                  <a class="text-primary decor-none font-weight-bold"
                    >@abraceumacausa.com.br.</a
                  >
                </p>
              </div>
            </v-flex>
          </v-layout>
        </Container>

        <div class="login-copyright">
          <v-layout w-100>
            <v-flex>
              Powered by
              <a
                href="https://www.abraceumacausa.com.br"
                target="_blank"
                class="ma-0"
                >abraceumacausa.com.br</a
              >
            </v-flex>
            <v-flex text-xs-right>
              <a href>Termos de uso</a>
              <a href>Política de Privacidade</a>
            </v-flex>
          </v-layout>
        </div>
      </v-layout>
    </v-container>
    <FileImage
      :file="getBg()"
      :useBg="true"
      class="bg-campaign"
      v-if="getBg() != null"
    />
    <div class="login-bg" v-else></div>
  </v-content>
</template>

<script type="plain/text">
import Container from "@/components/Container.vue";
import CommonHelper from "@/scripts/helpers/common.helper";
import UserService from "@/scripts/services/user.service";
import CampaignService from "@/scripts/services/campaign.service";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import CampaignLandingPageConfig from "@/scripts/models/campaignLandingPageConfig.model";
import FileImage from "@/components/FileImage.vue";
import Recaptcha from "@/components/login/Recaptcha.vue";

export default {
  components: {
    Container,
    FileImage,
    Recaptcha,
  },
  data() {
    return {
      username: "",
      userService: new UserService(),
      campaignService: new CampaignService(),
      landingPageConfig: new CampaignLandingPageConfig(),
      userEmail: null,
      recaptchaToken: null,
    };
  },
  created() {
    this.campaignService.getCampaignLandingPageConfigBySubdomain().then(
      function (data) {
        this.landingPageConfig = data;
      }.bind(this)
    );
    this.$store.commit("HIDE_LOGIN");
  },
  methods: {
    recaptchaSuccess(token) {
      this.recaptchaToken = token;
    },
    sendRecoverPasswordEmail() {
      debugger
      if (this.landingPageConfig.campaign.withRecaptcha && this.recaptchaToken == null) {
        CommonHelper.swal("Recaptcha inválido");
        return;
      }
      if (this.username.trim() == "") {
        CommonHelper.swal(
          "Informe o e-mail ou CPF para qual deseja recuperar a senha."
        );
      } else {
        let possibleCpfValue = this.username
          .replace(/\./g, "")
          .replace(/\-/g, "")
          .replace(" ", "");
        let currentUsername = this.username;
        if (CommonHelper.validateCPF(possibleCpfValue))
          currentUsername = possibleCpfValue;
        this.userService
          .sendRecoverPasswordEmail(currentUsername, this.recaptchaToken)
          .then((data) => {
            this.userEmail = data;
          });
      }
    },
    getLogo() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.campaign != null &&
        this.landingPageConfig.campaign.file != null
      ) {
        return this.landingPageConfig.campaign.file;
      }
      return null;
    },
    getBg() {
      if (
        this.landingPageConfig != null &&
        this.landingPageConfig.heroFile != null
      ) {
        return this.landingPageConfig.heroFile;
      }
      return null;
    },
  },
};
</script>